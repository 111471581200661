import {
  extend,
  bool as boolType,
  params,
  shape,
  string as stringType,
  arrayOf,
} from '@thd-nucleus/data-sources';

export const productConfiguratorDataModel = extend(
  {
    product: params({ itemId: stringType().isRequired() }).shape({
      itemId: stringType(),
      dataSource: stringType(),
      identifiers: shape({
        productLabel: stringType(),
      }),
    }),
  },
  {
    clientOnlyProduct: params({ itemId: stringType().isRequired }).shape({
      itemId: stringType(),
      dataSources: stringType(),
      info: shape({
        gccExperienceOmsId: stringType(),
        recommendationFlags: shape({
          visualNavigation: boolType(),
          pipCollections: boolType(),
          packages: boolType(),
          ACC: boolType(),
          collections: boolType(),
          frequentlyBoughtTogether: boolType(),
          bundles: boolType(),
          batItems: boolType(),
        }),
        dotComColorEligible: boolType(),
        globalCustomConfigurator: shape({
          customPosition: stringType(),
        }),
      }),
      identifiers: shape({
        roomVOEnabled: boolType(),
        productType: stringType(),
        skuClassification: stringType(),
      }),
      availabilityType: shape({
        discontinued: boolType(),
        type: stringType(),
      }),
      fulfillment: params({ storeId: stringType() }).shape({
        backordered: boolType(),
        fulfillmentOptions: arrayOf(
          shape({
            type: stringType(),
            fulfillable: boolType(),
            services: arrayOf(
              shape({
                type: stringType(),
                locations: arrayOf(
                  shape({
                    isAnchor: boolType(),
                    inventory: shape({
                      isLimitedQuantity: boolType(),
                      isOutOfStock: boolType(),
                    }),
                  })
                ),
              })
            ),
          })
        ),
      }),
    }),
  },
  {
    clientOnlyProduct: params({ itemId: stringType().isRequired }).shape({
      seoDescription: stringType(),
    }),
  }
);
