import { createSelector } from 'reselect';
import { productDetailsSelector } from '../product/productDetailsSelector';
import { cartProductSelector } from '../cartProduct/cartProductSelector';
import productInfoNonDefaultSelector from '../productInfo/productInfoNonDefaultSelector';
import productsForCartSelector from '../productInfo/productsForCartSelector';
import { thdStoreInfoSelector } from '../thdStoreInfo/thdStoreInfoSelector';
import { configSelector } from '../config/configSelector';
import { skuMapSelector } from '../skuMap/skuMapSelector';
export default createSelector(
    productDetailsSelector,
    cartProductSelector,
    productInfoNonDefaultSelector,
    productsForCartSelector,
    thdStoreInfoSelector,
    configSelector,
    skuMapSelector,
    (productDetails, product, productInfoNonDefault, cartData, thdStoreInfo, config, skuMap) => {
        const { 
            attributes :{
              allowDuplicateSubSkus,
              isMajorAppliance,
            }
        } = productDetails;
        return {
            allowDuplicateSubSkus,
            isMajorAppliance,
            product,
            productInfoNonDefault,
            cartData,
            thdStoreInfo,
            config,
            skuMap,
        }
    }
);

