import { LOG_TO_NEW_RELIC } from '../actions/actionTypes';
import store from '../store/store';

export default (actionName, keyValuePairs) => window?.newrelic?.addPageAction(actionName, keyValuePairs);

export function logToNewRelic(actionName, payload) {
    store.dispatch({
        type: LOG_TO_NEW_RELIC,
        actionName: actionName,
        payload: payload,
    });
}
