import merge from 'lodash/merge';
import { UPDATE_CART_PRODUCT_CONFIGURATION } from '../actionTypes';
import { mapskus } from '../fulfillmentActions/getItemsBySkuMap';
import { FULFILLMENT_METHOD_APPLIANCE, LIFE_CYCLE_EVENT_CONFIGURATOR_DATA, LIFE_CYCLE_EVENT_CONFIGURATOR_LOADING } from '../hydration/constants';
import { cartProducts, createItemDetails } from './__createItemDetailsPerStore';
import configurationCartSelector from '../../selectors/configurationCart/configurationCartSelector';
const addConfigurationToCart = () => (dispatch, getState) => {
  const state = getState();
  const {
    allowDuplicateSubSkus,
    isMajorAppliance,
    product,
    productInfoNonDefault,
    cartData,
    thdStoreInfo,
    config,
    skuMap,
  } = configurationCartSelector(state);
  const { fulfillment, configuration } = product;
  const { subSkus } = skuMap;
  const items = mapskus(productInfoNonDefault, config, skuMap);
  const unavailableItemDetails = [];
  const addTHDProductToCart = () => {
    const mappedItemDetails = cartProducts(allowDuplicateSubSkus, subSkus, cartData).map(
      cartProduct => createItemDetails(
        cartProduct,
        items,
        thdStoreInfo,
        subSkus,
        fulfillment,
        productInfoNonDefault,
        configuration,
        isMajorAppliance,
        allowDuplicateSubSkus
      )
    )

    if (mappedItemDetails?.length <= 0 ) {
      items.forEach(x => {
        unavailableItemDetails.push({
          itemId: `${x.itemId}`,
          storeId: thdStoreInfo?.storeNumber,
          zipCode: thdStoreInfo?.zipcode,
          quantity: 1,
          fulfillmentMethod: isMajorAppliance ? FULFILLMENT_METHOD_APPLIANCE : configuration?.cart?.[0]?.fulfillmentMethod
        });
      })
    };

    const itemDetails = mappedItemDetails?.length > 0
    ? mappedItemDetails
    : unavailableItemDetails;
    const cart = configuration?.cart;

    if (cart !== itemDetails && itemDetails.length > 0) {
      items?.length !== cart?.length ? delete configuration?.cart : false;
      const itemIdsThatHveFulfillment = mappedItemDetails.map(mappedItem => Number(mappedItem.itemId));
      const itemsWithFulfillment = items.filter(item => itemIdsThatHveFulfillment.indexOf(item.itemId) >= 0);

      const parsedData = merge(
          {},
          { product },
          { product: {
            configuration: {
              deliveryTime:  configuration?.deliveryTime,
              cart: itemDetails,
              items: itemsWithFulfillment,
            }
          }
        }
      );

      LIFE_CYCLE_EVENT_BUS.trigger(LIFE_CYCLE_EVENT_CONFIGURATOR_DATA, { configuratorData: parsedData });
      LIFE_CYCLE_EVENT_BUS.trigger(LIFE_CYCLE_EVENT_CONFIGURATOR_LOADING , false);

      return Promise.resolve(dispatch({
        type: UPDATE_CART_PRODUCT_CONFIGURATION,
        data: parsedData,
      }));
    }
  };

  return addTHDProductToCart();
};


export default () => addConfigurationToCart();;
