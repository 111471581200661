import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import compact from 'lodash/compact';
import { LIFE_CYCLE_EVENT_CONFIGURATOR_LOADING, LIFE_CYCLE_EVENT_CONFIGURATOR_UNAVALIABLE } from '../../actions/hydration/constants'
export const hasErrorMessage = (opt) => opt.messages.find((m) => m.messageType === 'Error');

export const hasError = (options) => {
  const opts = filter(options, (o) => !isEmpty(o.messages));
  if (opts.length > 0) {
    LIFE_CYCLE_EVENT_BUS.trigger(LIFE_CYCLE_EVENT_CONFIGURATOR_UNAVALIABLE, { configuratorUnavailable: true });
    LIFE_CYCLE_EVENT_BUS.trigger(LIFE_CYCLE_EVENT_CONFIGURATOR_LOADING, false);
    return !!opts.find(hasErrorMessage);
  }
  return false;
};

export const getMappedSkus = (skuMap) => compact([skuMap.sku, ...skuMap.subSkus.map((sub) => sub.sku)]);

export const getSelectedChoices = (options, choices) => filter(
  choices,
  (choice) => options[choice.optionId].isActive
      && choice.isSelected,
);

export const getPriceData = (originalPrice, specialPrice) => ({
  originalPrice,
  specialPrice: specialPrice || originalPrice,
  isLowerPrice: specialPrice ? specialPrice < originalPrice : false,
});
